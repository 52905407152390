import React from "react";
import Navbar from "./global-components/navbar-v4";
import BannerV6 from "./section-components/banner-v6";
import Aboutv2 from "./section-components/about-v2";
import Featuresv1 from "./section-components/features-v1";
import ProSlider from "./section-components/product-slider-v4";
import BlogSlider from "./blog-components/blog-slider-v1";
import Gallery from "./section-components/gallery-v1";
import Apartment from "./section-components/apartment-v1";
import VideoV1 from "./section-components/video-v1";
import Category from "./section-components/category-v1";
import Testimonial from "./section-components/testimonial-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const Home_V7 = () => {
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <BannerV6 />
      <ProSlider />
      {/* <Aboutv2 /> */}
      {/* <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" /> */}
      <Gallery />
      {/* <Apartment CustomClass="pt-115" /> */}
      {/* <VideoV1 /> */}
      <Category />
      {/* <Testimonial /> */}
      {/* <BlogSlider customClass="section-subtitle-2" /> */}
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Home_V7;
